@import './variables';

%primaryTitleStyle {
  color: #ffffff;
  font-family: $primary-title-font;
  font-weight: bold;
}

%secondaryTitleStyle {
  color: #ffffff;
  font-family: $secondary-title-font;
  letter-spacing: -0.25px;
  font-weight: 500;
}

%tertiaryTitleStyle {
  color: #ffffff;
  font-family: $tertiary-title-font;
  font-weight: 300;
  letter-spacing: -0.25px;
}

%quartenaryTitleStyle {
  color: #ffffff;
  font-family: $quaternary-title-font;
  font-weight: 300;
  letter-spacing: -0.25px;
}

@for $i from 0 through 2 {
  .header#{$i} {
    @extend %primaryTitleStyle;
  }
}

@for $i from 3 through 6 {
  .header#{$i} {
    @extend %secondaryTitleStyle;
  }
}

.header0 {
  font-size: 66px;
  line-height: 62px;
}

.header1 {
  font-size: 44px;
  line-height: 40px;
}

.header2 {
  font-size: 32px;
  line-height: 30px;
}

.header3 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 26px;
  line-height: 26px;
}

.header4 {
  font-size: 18px;
  line-height: 24px;
}

.header5 {
  font-size: 16px;
  line-height: 24px;
}

.title {
  @extend %primaryTitleStyle;
  letter-spacing: 0.5px;
  font-size: 20px;
  line-height: 24px;
}

.cta {
  @extend %secondaryTitleStyle;
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 1.4;
}

.body {
  font-family: $font-family;
  font-size: $font-size-default;
  color: rgba($primary-text-color, 0.7);
  letter-spacing: -0.25px;
  line-height: 1.4;
}

.tooltip {
  color: rgba(255, 255, 255, 1);
  font-size: 13px;
  letter-spacing: normal;
  line-height: 1.2;
}

.subtitle {
  @extend %secondaryTitleStyle;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 23px;
}

.category {
  color: #ffffff;
  font-family: $quaternary-title-font;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.small {
  color: #ffffff;
  font-family: $quaternary-title-font;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}

.color-error {
  color: $error-color;
}

@mixin primary-title() {
  @extend %primaryTitleStyle;
}

@mixin secondary-title() {
  @extend %secondaryTitleStyle;
}

@mixin tertiary-title() {
  @extend %tertiaryTitleStyle;
}

@mixin quartenary-title() {
  @extend %quartenaryTitleStyle;
}
