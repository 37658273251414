@import './base';
@import './variables';

.onboarding-sidebar {
	.help-list {
		margin-top: 15px;
		margin-bottom: 30px;
		list-style-type: none;
		font-size: 14px;
		color: $onboarding-tertiary-text-color;

		li:first-child {
			border-bottom: 0;
		}

		&__list-item {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: 4em;
			border: 1px solid $onboarding-primary-border-color;
			border-left: 0 solid;
			border-right: 0 solid;
		}

		&__list-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 1em;
			background: rgb(56, 78, 86);
			background: linear-gradient(
				45deg,
				rgba(56, 78, 86, 1) 25%,
				rgba(48, 51, 54, 1) 50%,
				rgba(29, 35, 46, 1) 75%
			);
			width: 30px;
			height: 30px;
			border-radius: 50%;

			> img {
				height: 60%;
			}
		}

		&__channel-text {
			font-family: $rtl-font-medium;
			color: $onboarding-tertiary-text-color;
		}

		&__time {
			padding-left: 5px;
			font-family: $rtl-font-regular;
		}
	}
}
