@import './base';
@import './mixins';

.onboarding-button {
	$border-width: 2px;
	$transition-speed: 150ms;
	$transition-ease: ease;
	position: relative;
	font-family: $rtl-font-medium;
	background-color: $onboarding-button-bg;
	border: $border-width solid $onboarding-button-bg;
	line-height: calc(3em - #{$border-width * 2});
	background-clip: padding-box;
	color: #fff;
	font-size: 1em;
	border-radius: 1.5em;
	min-width: 100%;
	margin: 1.2em 0;
	outline: none;
	text-align: center;
	transition:
		background-color $transition-speed $transition-ease,
		border-color $transition-speed $transition-ease,
		opacity $transition-speed $transition-ease;
	box-sizing: border-box;
	display: inline-block;

	&:before {
		content: '';
		background-repeat: no-repeat;
		position: absolute;
		left: 2em;
		top: 1em;
		width: 1em;
		height: 1em;
	}

	&:hover {
		border-color: $onboarding-button-bg-hover;
		background-color: $onboarding-button-bg-hover;
	}

	@include focus-visible() {
		border-color: $onboarding-button-second-border-hover;
		background-color: $onboarding-button-bg-hover;
	}

	&--disabled {
		opacity: 0.2;
		pointer-events: none;
	}

	&--inline {
		display: inline-block;
		min-width: 0;
		padding: 0 3em;
	}

	&--adaptive {
		padding: 0 1em;
		@include media('>=small') {
			display: inline-block;
			min-width: 0;
			padding: 0 3em;
		}
	}

	&--second {
		background-color: $onboarding-button-second-bg;
		border-color: $onboarding-button-second-bg;
		&:hover {
			background-color: $onboarding-button-second-bg;
			border-color: $onboarding-button-second-border-hover;
		}
		@include focus-visible() {
			background-color: $onboarding-button-second-bg;
			border-color: $onboarding-button-second-border-hover;
		}
	}

	&--light {
		background-color: $light;
		border: 2px $onboarding-button-light-border-color solid;
		color: $onboarding-tertiary-text-color;
		&:hover {
			background-color: transparent;
			border-color: $onboarding-button-light-border-color-hover;
		}
		@include focus-visible() {
			background-color: transparent;
			border-color: $onboarding-button-light-border-color-hover;
		}
		&:before {
			top: 1.1em;
		}
	}

	&--loading {
		span {
			opacity: 0;
		}
	}
}
