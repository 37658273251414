@import './base';
@import 'variables';
@import './mixins';

.help-sidebar {
  &__open-sidebar {
    position: absolute;
    z-index: 3;
    top: 2em;
    right: 1.625em;
    width: 2em;
    height: 2em;

    color: $onboarding-primary-text-color;
    background-color: $onboarding-button-second-bg;
    background-image: url('../../../../../static/images/onboarding/help-icon.svg');
    background-repeat: no-repeat;
    background-size: 0.5em;
    background-position: center;

    border-radius: 1em;
    border: 2px solid $transparent-primary-background-color;
    box-sizing: border-box;

    transition: border-color 150ms ease;

    &:hover {
      border-color: $onboarding-primary-text-color;
    }

    @include media('>=small') {
      right: 3em;
    }
  }

  &__list {
    margin-top: 2em;
    list-style-type: none;
  }

  &__list-icon {
    margin-right: 1em;
  }

  &__title {
    font-family: $rtl-font-bold;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 32px;
    font-weight: normal;
    letter-spacing: -0.5pt;
  }

  &__subtitle {
    text-align: left;
    font-family: $rtl-font-medium;
    font-size: 14px;
    line-height: 16px;
  }

  .onboarding-button {
    font-size: 1.6rem;
    display: block;
    margin: 1.2em auto;
    min-width: 300px;
    width: 300px;

    @include focus-visible() {
      border: 2px solid $onboarding-button-light-border-color-hover;
    }

    &--light {
      @include focus-visible() {
        border: 2px solid $onboarding-button-light-border-color-hover;
      }
    }
  }
}

.tier-options-sidebar {
  .onboarding__heading {
    color: inherit;
    line-height: 30px;
    margin-bottom: 2rem;
  }

  &__subtitle {
    text-align: left;
    font-family: $rtl-font-medium;
    font-size: 14px;
    line-height: 16px;
  }

  &__list {
    list-style-type: '- ';
    padding-left: 12px;
    li {
      margin-bottom: 10px;
    }
  }
}
