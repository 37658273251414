@import './base';

.error-container {
  height: 3rem;
  position: relative;
  text-align: center;
  width: 100%;
}

.error-message {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.6rem;
  color: $cancel-color;
}
