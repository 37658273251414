$spinner-color: #fff;

.spinner {
  stroke: $spinner-color;
  fill: $spinner-color;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: relative;
  backface-visibility: hidden;

  &.spinner-rotate {
    animation-name: rotate;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  .stop1 {
    stop-color: $spinner-color;
    stop-opacity: 0;
  }

  .stop2 {
    stop-color: $spinner-color;
    stop-opacity: 1;
  }
}

.spinner-large {
  height: 50px;
  width: 50px;
  margin: 10% 0;

  &.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px -25px 0 0;
  }
}

.spinner-small {
  height: 30px;
  width: 30px;
  margin: 3% 0;
}

.spinner--kids {
  animation: rotate 1.5s infinite linear;
  display: inline-block;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 6em;
}

.spinner--onboarding {
  left: 0;
  margin-right: -50px;
}

@keyframes rotate {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
