@import './base';

.footer {
	height: 60px;
	max-width: 355px;
	box-sizing: border-box;
	padding: 10px 1em 0;
	margin: 0 auto;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;

	&--fullwidth {
		@include media('>header-break') {
			max-width: none;
			.footer-nav {
				&__list {
					display: flex;
					justify-content: center;
				}
				&__item {
					display: inline-block;
					vertical-align: top;
					margin-right: 20px;
				}
			}
		}
	}

	&-usabilla {
		backface-visibility: hidden;
		bottom: 0;
		color: $light;
		height: 6rem;
		padding: 2rem 0 0;
		position: fixed;
		right: 3rem;
		z-index: 1000;
	}

	&-nav {
		font-size: 0.75em;
		width: 100%;
		&__link {
			color: inherit;
			text-decoration: underline;
			border: 0;
		}
		&__list {
			list-style: none;
			display: grid;
			grid-template-columns: auto auto auto;
			flex-wrap: wrap;
		}
	}

	@include media('<=header-break') {
		&-usabilla {
			display: none;
		}
	}
}

@include media('<desktop') {
	.footer--fixed-button {
		height: 140px;
		align-items: flex-start;
	}
}
