@import './base';

.usabilla {
  backface-visibility: hidden;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.usabilla__balloon {
  @extend .title;
  background: $primary-text-color;
  border-radius: 20px;
  box-shadow: 1px 1px 10px $primary-bg-color;
  color: $primary-bg-color;
  display: block;
  height: 4rem;
  padding: 1rem 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(0, 0, 0);

  @include focus-visible() {
    border: 1px solid $onboarding-button-second-border-hover;
    background: rgba(255, 255, 255, 0.16);
    color: $primary-text-color;
    outline: none;
  }
}

.usabilla__modal > * {
  background: $primary-text-color;
  border-radius: 4px;
  bottom: 4rem;
  color: $primary-bg-color;
  font-size: 1rem;
  height: 20rem;
  overflow: hidden;
  padding: 1.8rem;
  position: absolute;
  right: 2rem;
  text-align: center;
  text-transform: uppercase;
  width: 22rem;
  z-index: 10;
}

.usabilla__item {
  padding: 1rem 0 0;
  position: relative;

  &:first-child {
    padding: 0;
  }

  &:last-child {
    padding: 1.5rem 0 0;
  }
}

.usabilla-link,
.usabilla-link:visited,
.usabilla-link:hover,
.usabilla-link:focus {
  color: $primary-bg-color;
  display: block;
  text-decoration: none;
  transition: border 0.1s ease-out, color 0.1s ease-out, background 0.1s ease-out;
  width: 100%;

  &.vl-button {
    color: $primary-text-color;

    &:hover {
      background: transparent;
      border: 2px solid $primary-bg-color;
      color: $primary-bg-color;
    }

    &.vl-button--secondary {
      background: transparent;
      border: 2px solid rgba($primary-bg-color, 0.4);
      color: rgba($primary-bg-color, 0.8);

      &:hover {
        background: transparent;
        border: 2px solid $primary-bg-color;
        color: $primary-bg-color;
      }
    }
  }
}

.usabilla-appear {
  display: block;
  transform: translate3d(7rem, 7rem, 0);
}

.usabilla-appear.usabilla-appear-active {
  transform: translate3d(0, 0, 0);
  transition: transform 0.4s cubic-bezier(0, 1.8, 1, 1.8);
}
