@import './variables';
@import './mixins';

.onboarding {
	font-family: $rtl-font-regular;
	display: flex;
	flex-direction: column;

	.userstatus {
		margin-bottom: 40px;
	}

	.desktop-only {
		display: none;
	}

	@include desktop-only() {
		.desktop-only {
			display: inline;
		}
	}

	&__content {
		position: relative;
		flex: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__container--normal {
		max-width: 100%;
	}
	.onboarding__container {
		max-width: 325px;
		width: 100%;
		padding: 0 1em;

		@include media('>=325px') {
			box-sizing: content-box;
		}

		&--loading {
			.spinner-large {
				margin-top: 6em;
			}
		}

		&--wide {
			max-width: 400px;
			width: auto;
			padding: 0 1.5em;
		}

		&--wider {
			max-width: 500px;
			width: auto;
			padding: 0 1.5em;
		}
	}

	.onboarding__container:has(.cancellation-invoices-container) {
		max-width: 100%;
		width: 100%;
		padding: 0;
		@include media('>tablet') {
			width: inherit;
		}
	}

	.spinner-large {
		top: 5rem;
	}

	&__start {
		justify-content: center;
		flex-direction: column;
		display: flex;
		@include media('>=small') {
			display: block;
		}
	}

	&__transition {
		&-enter {
			opacity: 0;
			transform: translateX(100%);
			transition: all 0.4s ease;
		}
		&-enter-active {
			opacity: 1;
			transform: translateX(0);
		}
		&-exit {
			opacity: 1;
			transform: translateX(0);
			transition: all 0.4s ease;
		}
		&-exit-active {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			transform: translateX(-100%);
		}
	}

	&__form {
		.onboarding__heading--medium {
			margin-top: 5rem;
			margin-bottom: 3rem;
		}
		.onboarding__heading--large {
			margin-bottom: 3rem;
		}
		&--message {
			text-align: center;
		}
	}

	&__options {
		display: flex;
		justify-content: center;
	}
	&__radio-inputs {
		width: 100%;
		display: flex;
		align-items: stretch;
		padding-top: 2.5rem;
	}

	&__text {
		text-align: center;
		margin: 0.95rem 0;
		white-space: pre-line;
		line-height: 1.5;

		@include media('>=desktop') {
			margin: 1.5rem 0;
		}

		&--gray {
			color: $onboarding-secondary-text-color;

			a {
				color: $onboarding-primary-text-color;
			}
		}
	}

	&__bold {
		font-family: $rtl-font-bold;
		font-weight: normal;
	}

	&__link {
		color: $onboarding-primary-text-color;
		font-family: $rtl-font-regular;
		text-decoration: underline;
		text-decoration-color: $transparent-underline-color;
		border: 0;
		@include focus-visible() {
			outline: 1px solid $onboarding-form-field-focus;
			outline-offset: 3px;
		}
	}

	a:not(.onboarding-button),
	.cursor--pointer {
		@include focus-visible() {
			outline: 1px solid $onboarding-form-field-focus;
			outline-offset: 3px;
		}
	}

	.button {
		&--action,
		&--brand {
			@include focus-visible() {
				border-color: $onboarding-button-second-border-hover;
			}
		}
	}
}
