@font-face {
  font-family: 'BebasNeue-Bold';
  src: url('./fonts/BebasNeue-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('./fonts/Gotham-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('./fonts/Gotham-Book.woff') format('woff');
  font-display: swap;
}
