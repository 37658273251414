@import './base';

.onboarding-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.25em 0 1.8em 0;
  margin: 0 1em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;

  @include media('>=small') {
    margin: 0 3em;
  }

  &__logo {
    height: 1.5em;
  }
}
