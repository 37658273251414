@import './variables';

body,
h1,
h2,
h3,
html,
li,
p,
ul,
div,
span,
section,
article,
figure {
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 62.5%;
  height: 100%;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  @extend .body;
  background-color: $primary-bg-color;
  overflow-x: hidden;
  will-change: scroll-position;
  height: 100%;
}

.player {
  height: 100%;
  position: absolute;
  width: 100%;
}

.app-content {
  .main-header,
  .video-list-filter {
    transform: translate3d(0, 0, 0);
    transition: transform 0.1s ease-out;

    .app--scrolling & {
      transform: translate3d(0, -$header-height - 0.2, 0);
      transition: transform 0.3s ease-out;
    }
  }
}

.page-content {
  padding-top: $header-height;

  @include media('<=header-break') {
    padding-top: $header-height-mobile;
  }
}

body,
.styled-scroll {
  scrollbar-arrow-color: $primary-bg-color;
  scrollbar-base-color: $primary-text-color;
  scrollbar-highlight-color: $primary-bg-color;
  scrollbar-track-color: $primary-bg-color;

  &::-webkit-scrollbar {
    height: $scrollbar-width;
    width: $scrollbar-width * 1.5;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($primary-text-color, 0.25);
    border-radius: $scrollbar-width / 2;

    &:hover {
      background: rgba($primary-text-color, 0.55);
    }

    &:active {
      background: rgba($primary-text-color, 0.75);
    }
  }

  .styled-scroll--horizontal::-webkit-scrollbar {
    height: $scrollbar-width * 1.5;
    width: $scrollbar-width;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}

.center {
  text-align: center;
}

img {
  //IE Fix
  border: 0;
  text-decoration: none;
}

.clear-fix {
  &:after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
  }
}

.fade {
  position: relative;
}

.disabled {
  pointer-events: none;
}

.error {
  &::-webkit-input-placeholder {
    color: $error-color;
  }

  &::-moz-placeholder {
    color: $error-color;
  }

  &:-ms-input-placeholder {
    color: $error-color;
  }
}

[class$='__no-results'] {
  font-size: 1.2em;
  font-weight: normal;
  margin: 0 auto;
  max-width: 30em;
  opacity: 0.5;
  padding: 5rem 3rem 0;
  text-align: center;
}

.overlay-with-spinner {
  position: relative;

  &:before {
    content: '';
    z-index: 1;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.1s background-color;
    background-color: $transparent-primary-background-color-hover;
  }

  .spinner {
    position: absolute;
    z-index: 2;
    top: 30%;
    left: 50%;
  }
}

.cursor {
  &--pointer {
    cursor: pointer;
  }
}

@include colors();
