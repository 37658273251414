.onboarding-loader {
  pointer-events: none;

  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    width: 2em;
    height: 2em;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../../../static/images/onboarding/button-loading.png');
    transform: translate(-50%, -50%);
    animation: 1s rotate infinite ease-in-out;

    @keyframes rotate {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  }
}
