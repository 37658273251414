@import './base';

.layout {
	> .app-content {
		min-height: 100vh;
		margin-bottom: -60px;
		&-tvgemist {
			background-image: url('../../../../static/images/tv-gemist/finalize.png');
			background-repeat: no-repeat;
			background-size: cover;
		}
		&-soft-landing {
			position: relative;
			&:before {
				position: absolute;
				content: '';
				top: 85px;
				left: 0;
				right: 0;
				bottom: 0;
				background-image: url('../../../../static/images/onboarding/softlanding.jpg');
				background-repeat: no-repeat;
				background-size: 100% auto;
				opacity: 0.8;
				z-index: -1;
			}
			&:after {
				position: absolute;
				content: '';
				top: 85px;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 0) 9%,
					rgba(0, 0, 0, 0) 14%,
					rgba(0, 0, 0, 0) 18%,
					rgba(0, 0, 0, 0) 19%,
					rgba(0, 0, 0, 1) 54%,
					rgba(0, 0, 0, 1) 100%
				);
				z-index: -1;
			}
			.onboarding__container {
				padding: 35vh 0 15vh;
				max-width: 600px;
			}
		}
	}
	&__push {
		height: 60px;
	}
}

@include media('<desktop') {
	.layout > .app-content-soft-landing {
		&:after {
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0) 9%,
				rgba(0, 0, 0, 0) 14%,
				rgba(0, 0, 0, 1) 39%,
				rgba(0, 0, 0, 1) 100%
			);
		}
	}
	.layout > .app-content-fixed-button {
		margin-bottom: -140px;
		.layout__push {
			height: 140px;
		}
	}
}

@include media('<tablet') {
	.layout {
		> .app-content {
			&-soft-landing {
				&:before {
					background-image: url('../../../../static/images/onboarding/softlanding-mobile.jpg');
				}
				&:after {
					background: linear-gradient(
						to bottom,
						rgba(0, 0, 0, 0) 0%,
						rgba(0, 0, 0, 0) 9%,
						rgba(0, 0, 0, 0) 14%,
						rgba(0, 0, 0, 0) 18%,
						rgba(0, 0, 0, 0) 19%,
						rgba(0, 0, 0, 1) 40%,
						rgba(0, 0, 0, 1) 100%
					);
				}
				.onboarding__container {
					padding: 20vh 0 10vh;
					max-width: 90%;
				}
			}
		}
	}
}
