@import './base';

$icon-spacing: 16px;
$extraSmallBreakpoint: map-get($breakpoints, 'extra-small');
$logoSpacingBreakpoint: calc(#{$extraSmallBreakpoint} + 30px);

.filter-button {
  margin-left: 0.5em;
  top: 16px;
  right: 94px;
  bottom: 0;
}

.icon-watchlist {
  margin: 0.4rem 2rem 0 0;
  height: 2.2rem;

  .navigation__link-text {
    @extend .body;
    font-weight: 700;
    vertical-align: top;
    margin: 0 1.2rem;
    color: $primary-text-color;
  }
}

.main-header-controls {
  top: 0;
  right: 0;
  bottom: 0;
}

.header__action-login {
  padding: 0.57em 1em 0.6em;
}

.header__action-button {
  left: 2rem;
  line-height: 1rem;
  margin: 0;
  opacity: 0.7;
  padding: 0;

  &.header__action-button--right {
    right: 2rem;
  }

  &.filter-button {
    opacity: 1;
    right: 10em;

    @include media('>small') {
      right: 12em;
    }

    @include desktop-only() {
      display: none;
    }
  }

  .header__icon {
    display: inline-block;
    font-size: 2.4rem;
  }

  .header__icon-back.icon {
    display: inline-block;
    font-size: 1.1rem;
    position: absolute;
    top: 50%;
    transform: rotate(90deg) translateX(-25%);
  }

  .header__text-back {
    font-size: 2rem;
    margin-left: 3rem;
  }

  &:hover {
    opacity: 1;
  }

  &.vl-button {
    background: transparent;
    border: 0;
    padding: 0.2rem 0 0.3rem;

    &:hover {
      background: transparent;
      border: 0;
    }
  }
}

.main-header,
.main-header-container {
  top: 0;
  width: 100%;
  z-index: 101;
}

.main-header-container {
  position: fixed;

  .header-menu {
    z-index: -1;
  }
}

.main-logo--link {
  display: block;
  float: left;
  height: 18px;
  margin-right: 15px;

  @include focus-visible() {
    outline-offset: 1px;
    outline: 1px solid $onboarding-button-second-border-hover;
  }

  @include desktop-only() {
    height: 23px;
  }

  .main-logo {
    height: 100%;
  }

  .main-header--has-filter & {
    height: 12px;
    @media (min-width: $logoSpacingBreakpoint) {
      height: 18px;
    }
    @include desktop-only() {
      height: 23px;
    }
  }
}

.main-header {
  background-color: $primary-bg-color;
  font-size: 0.5em;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: $header-height;
  padding: 1.1rem 0;

  &:not(.main-header-active) {
    .navigation__link.active {
      opacity: 1;
    }

    .active:before {
      width: 100%;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    float: right;

    & > * {
      margin-right: 2rem;
    }

    .icon-search {
      transform: translateY(0.3rem);
    }

    &-foldout-wrapper {
      position: absolute;
    }

    .mobile-select-button {
      position: relative;
      &__select {
        width: 100%;
      }
    }
  }
}

.action-icon.icon {
  cursor: pointer;
  display: inline-block;
  font-size: 2.2rem;
  opacity: 0.7;
  transition: opacity 0.1s ease-out;

  @include focus-visible() {
    outline-offset: 1px;
    outline: 1px solid $onboarding-button-second-border-hover;
  }

  &:hover {
    opacity: 1;
  }
}

.navigation__menu-small-screen-trigger {
  cursor: pointer;
  float: left;
  height: 22px;
  margin-right: 1.6rem;
  margin-top: 5px;
  width: 22px;

  @include desktop-only() {
    display: none;
  }
}

$navigation--menu-small-screen-trigger-border-width: 2px;
$navigation--menu-small-screen-trigger-width: 22px;

.navigation__menu-small-screen-trigger__icon {
  background-color: $light;
  height: $navigation--menu-small-screen-trigger-border-width;
  position: relative;
  width: $navigation--menu-small-screen-trigger-width;

  &:before,
  &:after {
    background-color: $light;
    content: '';
    height: $navigation--menu-small-screen-trigger-border-width;
    left: 0;
    position: absolute;
    top: 7px;
    width: $navigation--menu-small-screen-trigger-width;
  }

  &:after {
    top: 14px;
  }
}

.profile-menu__container {
  cursor: pointer;

  @include focus-visible() {
    outline-offset: 1px;
    outline: 1px solid $onboarding-button-second-border-hover;
  }

  @include desktop-only() {
    height: 3rem;
  }

  .profile-menu-icon {
    border-radius: 50%;
    font-size: 1.4rem;
    opacity: 1;
    padding: 8px;
    transition: background-color 0.3s ease-out;
  }
}

.navigation__links {
  display: none;
  float: left;
  @include desktop-only() {
    display: inline-flex;
  }

  .navigation__link__item {
    list-style: none;
  }
}

.navigation__icon {
  margin: 0.6rem 1rem 0 0;
  width: 2.4rem;
  height: 2.4rem;
  transition: opacity 0.1s ease-out;
  color: rgba($primary-text-color, 1);
  transform: translateY(10%);
  filter: invert(100%) sepia(0%) saturate(7476%) hue-rotate(64deg) brightness(102%) contrast(105%);

  @include media('>second-header-break') {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 1rem 0 0;
  }
}

.navigation {
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 1.6rem;
}

.navigation__link {
  @include focus-visible() {
    outline-offset: 1px;
    outline: 1px solid $onboarding-button-second-border-hover;
  }
  @extend .body;
  font-weight: 700;
  color: $primary-text-color;
  display: inline-block;
  height: 100%;
  margin: 0 1.5rem;
  padding: $active-border-size 0 0 $icon-spacing;
  position: relative;
  text-decoration: none;
  width: 100%;
  opacity: 0.7;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;

  &--desktop {
    @include media('<840px') {
      margin: 0 0.5rem;
    }
  }

  &.activeFilter {
    opacity: 1;
    font-weight: 700;
  }

  @include desktop-only() {
    width: 5rem;

    &:before,
    &:after {
      position: absolute;
    }

    &:before {
      background-color: $selected-color;
      content: '';
      display: block;
      height: 2px;
      top: 4.2rem;
      z-index: 1000;

      @include media('<second-header-break') {
        left: 2px;
      }

      @include media('>second-header-break') {
        top: 4rem;
      }
    }

    &.activeFilter:before,
    &:hover:before {
      opacity: 1;
      width: 100%;
    }
  }

  @include media('>second-header-break') {
    padding: 10px 0 4px;
    width: auto;
  }

  &:hover .navigation__icon {
    opacity: 1;
  }
}

.navigation__link-text {
  display: none;

  @include media('>second-header-break') {
    display: inline-block;
  }
}

.main-header-container-bedrock {
  .main-logo--link {
    display: inline-block;
    vertical-align: middle;
    float: none;
    margin-right: 0;
  }
  .navigation {
    width: 100%;
    display: block;
    text-align: center;
    justify-content: space-between;
    padding: 0 1rem 0 calc(37px + 1rem);
  }
  .main-header__right {
    list-style: none;
    border-bottom: 2px solid #ff0000;
    padding-bottom: 5px;
    align-self: baseline;
    width: 37px;
  }
  .icon-settings {
    display: inline-block;
    font-size: 2.3rem;
  }
  .main-header__right {
    align-items: center;
    > * {
      margin: 0 1.5rem 0 0;
      height: 30px;
      line-height: 30px;
      .icon,
      img,
      .navigation__link-text {
        vertical-align: middle;
        transform: none;
      }
    }
    @include media('<second-header-break') {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .header-menu__settings {
    display: inline-block;
    opacity: 0.7;
    transition: opacity 0.1s ease-out;
    &:hover {
      opacity: 1;
    }
  }
}
