@import './base';

.onboarding .onboarding-generic-error {
  overflow: hidden;
  padding-bottom: 4em;
  @include media('>=small') {
    padding-bottom: 0;
  }
  .onboarding-generic-error {
    &__button {
      margin-top: 0;
    }
    &__text {
      margin-top: 0;
    }
    &__link {
      text-decoration: none;
    }
  }
}
