@import './variables';
@import '~include-media/dist/include-media';

@mixin render-strip-breakpoints($breakpoints, $element) {
  @each $breakpoint, $number-of-strip-items in $breakpoints {
    @media (min-width: #{$breakpoint}) {
      #{$element} {
        width: 100 / $number-of-strip-items * 1%;
        height: 100 / $number-of-strip-items * 1% * 3 / 4;
      }
    }
  }
}

@mixin render-strip-breakpoints-double($breakpoints, $element) {
  @each $breakpoint, $number-of-strip-items in $breakpoints {
    @media (min-width: #{$breakpoint}) {
      #{$element} {
        width: calc(#{100 / $number-of-strip-items * 2%} - 28px);
      }
    }
  }
}

@mixin render-collection-breakpoints($breakpoints, $element) {
  @each $breakpoint, $size in $breakpoints {
    @media (min-width: #{$breakpoint}) {
      #{$element} {
        height: $size;
        width: $size;
      }
    }
  }
}

@mixin render-still-breakpoints($breakpoints, $element) {
  @each $breakpoint, $number-of-strip-items in $breakpoints {
    @media (min-width: #{$breakpoint}) {
      #{$element} {
        width: 100 / $number-of-strip-items * 1%;
      }
    }
  }
}

@mixin render-font-sizes($breakpoints, $element) {
  @each $breakpoint, $font-size in $breakpoints {
    @media (min-width: #{$breakpoint}) {
      #{$element} {
        font-size: $font-size;
      }
    }
  }
}

@mixin render-strip-heights($breakpoints, $element) {
  @each $breakpoint, $height in $breakpoints {
    @media (min-width: #{$breakpoint}) {
      #{$element} {
        min-height: $height;
      }
    }
  }
}

@mixin render-box-shadow($base-color) {
  box-shadow: $kids-shadow darken($base-color, 25);
}

@mixin colors() {
  &.blue,
  &.cobalt {
    background-color: $cobalt;
    border-color: $cobalt;

    &-75 {
      background-color: rgba($cobalt, 0.75);
      border-color: rgba($cobalt, 0.75);
    }
  }

  &.crimson {
    background-color: $crimson;
    border-color: $crimson;

    &-75 {
      background-color: rgba($crimson, 0.75);
      border-color: rgba($crimson, 0.75);
    }
  }

  &.green,
  &.emerald {
    background-color: $emerald;
    border-color: $emerald;

    &-75 {
      background-color: rgba($emerald, 0.75);
      border-color: rgba($emerald, 0.75);
    }
  }

  &.pink,
  &.flamingo {
    background-color: $flamingo;
    border-color: $flamingo;

    &-75 {
      background-color: rgba($flamingo, 0.75);
      border-color: rgba($flamingo, 0.75);
    }
  }

  &.yellow,
  &.honey {
    background-color: $honey;
    border-color: $honey;

    &-75 {
      background-color: rgba($honey, 0.75);
      border-color: rgba($honey, 0.75);
    }
  }

  &.purple,
  &.iris {
    background-color: $iris;
    border-color: $iris;

    &-75 {
      background-color: rgba($iris, 0.75);
      border-color: rgba($iris, 0.75);
    }
  }

  &.aqua,
  &.lime {
    background-color: $lime;
    border-color: $lime;

    &-75 {
      background-color: rgba($lime, 0.75);
      border-color: rgba($lime, 0.75);
    }
  }

  &.orange {
    background-color: $orange;
    border-color: $orange;

    &-75 {
      background-color: rgba($orange, 0.75);
      border-color: rgba($orange, 0.75);
    }
  }

  &.red {
    background-color: $red;
    border-color: $red;

    &-75 {
      background-color: rgba($red, 0.75);
      border-color: rgba($red, 0.75);
    }
  }

  &.malibu,
  &.slate {
    background-color: $slate;
    border-color: $slate;

    &-75 {
      background-color: rgba($slate, 0.75);
      border-color: rgba($slate, 0.75);
    }
  }
}

@mixin desktop-only() {
  @include media('>header-break') {
    @content;
  }
}

@mixin small-screen-only() {
  @include media('<=header-break') {
    @content;
  }
}

@mixin focus-visible() {
  &.focus-visible {
    @content;
  }

  &:focus-visible {
    @content;
  }
}
