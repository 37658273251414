@import './base';

.onboarding__heading {
	font-family: $rtl-font-bold;
	color: $onboarding-primary-text-color;
	text-align: center;
	line-height: 1.05em;
	letter-spacing: -0.5pt;
	font-weight: normal;

	&--large {
		font-size: 3.8rem;
		line-height: 4.4rem;
		margin: 0 0 0.8em 0;
		@include media('>=small') {
			margin-top: 4.4rem;
		}
		@include media('>=desktop') {
			margin-top: 8.4rem;
		}
	}

	&--medium {
		font-size: 3.2rem;
		line-height: 3.6rem;
		width: 100%;
		margin: 4.4rem auto 0 auto;

		@include media('>=desktop') {
			margin-top: 8.4rem;
			position: relative;
		}
	}

	&--small {
		width: 100%;
		font-size: 1.1em;
		margin: 0 0 1.5rem 0;

		@include media('>=small') {
			font-size: 1.375em;
		}
	}

	&--align-left {
		text-align: left;
	}
	&--align-center {
		text-align: center;
	}
	&--align-right {
		text-align: right;
	}
	&--no-top-offset {
		margin-top: 1.5em;
	}
}
