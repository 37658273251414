@import './base';

.full-page-message {
	.onboarding__heading {
		margin-top: 5.5rem;

		@include media('>=desktop') {
			margin-top: 8rem;
		}
	}

	.onboarding__image {
		display: block;
		margin: 5rem auto 2rem;
		width: 113px;

		@include media('>=desktop') {
			margin-top: 10rem;
		}
	}
	&--softlanding {
		.onboarding__text {
			color: $primary-text-color;
			margin-bottom: 15px;
		}
		.full-page-message__heading {
			margin-bottom: 20px;
		}
		.onboarding-button {
			font-size: 18px;
			margin: 20px 0 0;
			line-height: normal;
			padding: 14px 10px;
			border-radius: 2em;
			min-width: 0;
			width: 100%;
			max-width: 360px;
			&--light {
				margin: 0;
				background-color: transparent;
				border: 1px solid $primary-text-color;
				color: #f8f8f8;
				text-decoration: none;
				margin: 0;
				&:hover {
					background-color: $primary-text-color;
					color: $primary-bg-color;
				}
			}
		}
	}
	.onboarding-button {
		min-width: 0;
	}
	.onboarding__text {
		&_light {
			color: $primary-text-color;
		}
	}
	a:not(.onboarding-button) {
		color: inherit;
		text-decoration: underline;
	}

	&__terms {
		font-style: italic;
		font-family: $quaternary-title-font;
		text-align: center;
	}
}

.full-page-message__heading {
	word-break: break-word;
}

.full-page-message--with-image {
	.onboarding__heading {
		margin-top: 2.5rem;

		@include media('>=desktop') {
			margin-top: 0;
		}
	}
}
